import { useEffect } from 'react';
import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { setupBordersForObject } from '../../../utils/setup-borders-for-object';

export const useCustomBordersAndShadows = (canvas: Canvas | null) => {
  useEffect(() => {
    if (!canvas) return;

    const existingObjects = canvas.getObjects();
    existingObjects.forEach((obj) => {
      setupBordersForObject(obj, canvas);
    });

    const onObjectAdded = (e: fabric.IEvent) => {
      const obj = e.target as fabric.Object;
      setupBordersForObject(obj, canvas);
    };

    const onObjectModified = (e: fabric.IEvent) => {
      const obj = e.target as fabric.Object;
      setupBordersForObject(obj, canvas);
    };

    const handleTextEditingEntered = (e: fabric.IEvent) => {
      if (e.target) {
        const target = e.target as fabric.Textbox;
        target.selectable = true;
        target.enterEditing();
        if (target.hiddenTextarea) {
          target.hiddenTextarea.focus();
        }
      }
    };

    canvas.on('object:added', onObjectAdded);
    canvas.on('object:modified', onObjectModified);
    canvas.on('text:editing:entered', handleTextEditingEntered);

    return () => {
      canvas.off('object:added', onObjectAdded);
      canvas.off('object:modified', onObjectModified);
      canvas.off('text:editing:entered', handleTextEditingEntered);
    };
  }, [canvas]);
};
