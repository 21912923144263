import {
  PodMainPageLoaded,
  PodTextAdded,
  PodPhotoAdded,
  PodStickerAdded,
  PodHandwritingAdded,
  PodTextInspirationAdded,
  PodInsideCardLoaded,
  PodCardPreview,
  PodSaveCard,
  AddToCart,
} from './analytics-types';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Pushes a POD main page loaded event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodMainPageLoaded = (eventData: Omit<PodMainPageLoaded, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD text added event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodTextAdded = (eventData: Omit<PodTextAdded, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD photo added event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodPhotoAdded = (eventData: Omit<PodPhotoAdded, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD sticker added event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodStickerAdded = (eventData: Omit<PodStickerAdded, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD handwriting added event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodHandwritingAdded = (eventData: Omit<PodHandwritingAdded, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD text inspiration added event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodTextInspirationAdded = (eventData: Omit<PodTextInspirationAdded, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD inside card loaded event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodInsideCardLoaded = (eventData: Omit<PodInsideCardLoaded, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD card preview event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodCardPreview = (eventData: Omit<PodCardPreview, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes a POD save card event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushPodSaveCard = (eventData: Omit<PodSaveCard, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Pushes an add to cart event to the dataLayer.
 * @param eventData - The event data.
 */
export const pushAddToCart = (eventData: Omit<AddToCart, 'event_id'>): void => {
  const data = { ...eventData, event_id: generateEventId() };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * Generates a unique event ID by combining a timestamp and a 10-digit random number.
 * @returns {string} The generated event ID.
 */
export const generateEventId = (): string => {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1e10);
  return `${timestamp}.${randomNum}`;
};
