import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, ButtonModes, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAppContext } from '../../context/app-context';
import { setProductQuantity } from '../../context/app-context';
import { useQueryParams } from '../../hooks';
import { SummaryDrawer } from '../card-controls/summary-drawer';
import styles from './quantity-link.module.scss';

export const QuantityLink = () => {
  const [isProjectSummaryOpen, setIsProjectSummaryOpen] = useState(false);
  const { t } = useTranslation();

  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const { appState, appDispatch } = useAppContext();
  const { productQuantity } = appState;
  const hasQuantityUpdatedAfterPageLoad = useRef(false);

  useEffect(() => {
    if (productQuantity === queryParams.get('qty')) {
      hasQuantityUpdatedAfterPageLoad.current = true;
    }
  }, [productQuantity]);

  useEffect(() => {
    setProductQuantity(appDispatch, queryParams.get('qty') ?? '1');
  }, []);

  const onCloseSummary = () => {
    setIsProjectSummaryOpen(false);
  };

  const updateQuantity = useCallback(
    (qty: string) => {
      setProductQuantity(appDispatch, qty);
      queryParams.set('qty', qty);
      history.replace({ pathname, search: queryParams.toString() });
    },
    [queryParams, history],
  );

  return (
    <>
      <Button
        testId="quantity-link"
        click={() => setIsProjectSummaryOpen(!isProjectSummaryOpen)}
        type={ButtonTypes.Button}
        mode={ButtonModes.TextLink}
        addClass={styles['quantity-link']}
        endIcon={{ name: isProjectSummaryOpen ? IconNames.ArrowsCaretUpBold : IconNames.ArrowsCaretDownBold }}
      >
        {t('header.quantity', { productQuantity })}
      </Button>

      {isProjectSummaryOpen && (
        <SummaryDrawer
          isOpen={isProjectSummaryOpen}
          onClose={onCloseSummary}
          quantity={productQuantity}
          onUpdateQuantity={updateQuantity}
        />
      )}
    </>
  );
};
