import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroupVariant } from '@hallmark/web.core.buttons.button-group';
import { TextColorDrawer as TextColorDrawerComponent } from '@hallmark/web.page-components.print-on-demand.text-editor.text-color-drawer';
import { MoreButtonPosition } from '@hallmark/web.page-components.print-on-demand.text-editor.text-color-drawer/dist/text-color-drawer-types';
import { basicTextColors, extendedTextColors } from '../../../../constants';
import {
  useAppContext,
  setIsTextDrawerOpen,
  setIsColorDrawerOpen,
  setIsColorPalleteDrawerOpen,
  addColorToMoreColorsAdded,
} from '../../../../context/app-context';
import { useActiveCanvas } from '../../../../hooks';
import { config } from '../../../../regional-config';
import colorsList from '../../../../styles/util.scss';
import { ColorPalleteDrawer } from '../../color-pallete-drawer/color-pallete-drawer';
import styles from '../drawer-container.module.scss';

export const TextColorDrawer = () => {
  const {
    appState: { isColorDrawerOpen, isColorPalleteDrawerOpen, moreColorsAdded },
    appDispatch,
  } = useAppContext();
  const canvas = useActiveCanvas();
  const [colors, setColors] = useState([...basicTextColors, ...moreColorsAdded]);
  const [selectedColor, setSelectedColor] = useState('');
  const initialColor = useRef<string>();

  const { t } = useTranslation();

  useEffect(() => {
    setColors([...basicTextColors, ...moreColorsAdded]);
  }, [moreColorsAdded]);

  useEffect(() => {
    const currentFill = canvas?.current?.getActiveObject()?.fill;
    if (currentFill) {
      const color =
        Object.keys(colorsList).find((key) => {
          const colorHex = colorsList[`${key}`].toUpperCase();
          const currentFillHex = currentFill.toString().toUpperCase();
          return colorHex === currentFillHex && extendedTextColors.includes(key);
        }) ?? currentFill.toString();
      if (!colors.includes(color)) {
        addColorToMoreColorsAdded(appDispatch, color);
      }
      setSelectedColor(color);
      initialColor.current = color;
    }
  }, []);

  const setTextColor = (colorName: string) => {
    const hexColorValue = colorsList[`${colorName}`];
    const activeObject = canvas?.current?.getActiveObject() as fabric.Text;
    if (activeObject) {
      setSelectedColor(colorName);
      activeObject.setOptions({ fill: hexColorValue });
      canvas?.current?.renderAll();
    }
  };

  const onCancel = () => {
    const hexColorValue = colorsList[`${initialColor.current}`];
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject) {
      activeObject.setOptions({ fill: hexColorValue });
    }
    canvas?.current?.requestRenderAll();
    setIsTextDrawerOpen(appDispatch);
  };

  const handleMoreColorSelect = (colorName: string) => {
    if (!colors.includes(colorName)) {
      addColorToMoreColorsAdded(appDispatch, colorName);
    }
    setTextColor(colorName);
    setIsColorDrawerOpen(appDispatch);
  };

  const handleSubmit = () => {
    setIsTextDrawerOpen(appDispatch);
  };

  return (
    <>
      <ColorPalleteDrawer
        isOpen={isColorPalleteDrawerOpen}
        onClose={() => {
          setIsColorDrawerOpen(appDispatch);
        }}
        onColorSelect={handleMoreColorSelect}
        selectedColor={selectedColor}
      />
      <TextColorDrawerComponent
        moreColorsText={t('textColorDrawer.moreColors') ?? ''}
        isOpen={isColorDrawerOpen}
        addClass={styles['wam-color-drawer']}
        openAddMoreColorsDrawer={() => setIsColorPalleteDrawerOpen(appDispatch)}
        handleSubmit={handleSubmit}
        buttonGroupClick={(colorName: string) => setTextColor(colorName)}
        onClose={onCancel}
        colors={colors}
        selectedColor={selectedColor}
        moreColorsTextClass={styles['more-colors-text']}
        moreButtonPosition={config?.textColorDrawer?.moreButtonPosition ?? MoreButtonPosition.LEFT}
        buttonGroupMode={config?.textColorDrawer?.ButtonGroupVariant ?? ButtonGroupVariant.Primary}
        hasPurpleShadowEffect={config?.textColorDrawer?.hasPurpleShadowEffect ?? false}
        actionButtonLabel={t('textColorDrawer.actionButtonLabel') ?? ''}
        cancelButtonLabel={t('textColorDrawer.cancelButtonLabel') ?? ''}
        hasActionButton={config?.textColorDrawer?.hasActionButton ?? false}
        hasCancelButton={config?.textColorDrawer?.hasCancelButton ?? false}
        actionButtonMode={config?.textColorDrawer?.actionButtonMode ?? undefined}
        cancelButtonMode={config?.textColorDrawer?.cancelButtonMode ?? undefined}
        layoutVariant={config?.textColorDrawer?.layoutVariant ?? undefined}
      />
    </>
  );
};
